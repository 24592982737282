import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/base/resources-layout";
import SEO from "../components/base/seo";
import "../styles/pages/_resources.scss";

export default function TemplateResources({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO
        title={frontmatter.seotitle}
        description={frontmatter.seodescription}
      />
      <div className="single-resource-post">
        <section className="page-heading">
          <div className="container">
            <div
              className="single-resource-header-image"
              style={{
                backgroundImage: `url(
                ${frontmatter.imageUrl.childImageSharp.original.src}
              )`,
              }}
            ></div>
          </div>
        </section>
        <section>
          <div className="">
            <p className="single-resource-top-info container">
              {frontmatter.categories.map((cat, index) => {
                if (index === frontmatter.categories.length - 1) {
                  return cat;
                } else {
                  return cat + ", ";
                }
              })}{" "}
              | {frontmatter.date}
            </p>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        categories
        author
        seotitle
        seodescription
        imageUrl {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`;
