import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header/header";
import Footer from "./footer/footer";

const ResourcesLayout = ({ children, imageUrl }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <Header imageUrl={imageUrl} />
      <main className="template-resources">{children}</main>
      <Footer />
    </>
  );
};

ResourcesLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResourcesLayout;
